import { Controller } from "@hotwired/stimulus";
import Swal from "sweetalert2";
import I18n from "../i18n";

// Connects to data-controller="product-designer"
export default class extends Controller {
  static targets = ["customization", "customizationsCount", "content"];

  static values = {
    url: String,
    fileServerUrl: String,
  };

  initialize() {
    this.Toast = Swal.mixin({
      toast: true,
      position: "bottom-end",
      iconColor: "white",
      customClass: {
        popup: "colored-toast",
      },
      showConfirmButton: false,
      timer: 3000,
      timerProgressBar: true,
    });
  }

  disconnect() {
    this.designer = null;
  }

  open() {
    const content = this.contentTarget.innerHTML;

    Swal.fire({
      html: content,
      showCancelButton: true,
      confirmButtonText: I18n.t("products.show.save_customization"),
      cancelButtonText: I18n.t("products.show.close"),
      buttonsStyling: false,
      customClass: {
        confirmButton:
          "p-2 bg-black text-white-broken uppercase font-semibold text-sm cursor-pointer hover:bg-gray-dark mr-2",
        cancelButton:
          "p-2 bg-gray-dark text-white-broken uppercase font-semibold text-sm cursor-pointer hover:bg-gray-dark",
        popup: "swal2-product-designer-popup",
      },
      didOpen: (modal) => {
        this.setup(modal.querySelector("#product-designer-container"));
      },
    }).then((result) => {
      if (result.isConfirmed) {
        this.export();
      }
    });
  }

  export() {
    this.designer.getProductDataURL((dataURL) => {
      this.customizationTarget.value = dataURL;
      this.customizationsCountTarget.value =
        this.designer.getCustomElements().length;

      this.Toast.fire({
        icon: "success",
        title: I18n.t("products.show.customization_saved"),
      });

      document.getElementById("add-to-cart-button").click();
    });
  }

  setup(container) {
    this.designer = new FancyProductDesigner(container, {
      productsJSON: this.urlValue,
      langJSON: "/designer/lang.json",
      fileServerURL: this.fileServerUrlValue,
      unsavedProductAlert: false,
      toolbarTheme: "dark",
      priceFormat: {
        currency: "%d &euro;",
        decimalSep: ",",
        thousandSep: " ",
      },
      initialActiveModule: "products",
      mainBarModules: [
        "products",
        "images",
        "text",
        "manage-layers",
        "text-layers",
      ],
      actions: {
        left: ["preview-lightbox", "reset-product"],
        center: ["undo", "redo"],
        right: ["zoom", "ruler"],
      },
      toolbarPlacement: "smart",
      selectedColor: "#f5f5f5",
      boundingBoxColor: "#005ede",
      outOfBoundaryColor: "#990000",
      cornerIconColor: "#000000",
      guidedTour: {},
      customImageParameters: {
        minH: 0,
        minW: 0,
        minDPI: 72,
        maxH: 10000,
        maxW: 10000,
        removable: true,
        draggable: true,
        resizable: true,
        autoCenter: true,
        autoSelect: true,
        advancedEditing: true,
      },
      fonts: [
        { name: "DynaPuff", url: "google" },
        { name: "Chewy", url: "google" },
        { name: "Send Flowers", url: "google" },
        { name: "Sacramento", url: "google" },
        { name: "Imperial Script", url: "google" },
        { name: "Rouge Script", url: "google" },
        { name: "Iceland", url: "google" },
        { name: "DM Serif Text", url: "google" },
        { name: "Roboto", url: "google" },
        { name: "Akaya Kanadaka", url: "google" },
        { name: "Lexend", url: "google" },
      ],
      customTextParameters: {
        autoCenter: true,
        draggable: true,
        removable: true,
        colors: true,
      },
      pricingRules: [
        {
          property: "elementsLength",
          target: {
            views: -1,
            elements: "image",
          },
          type: "all",
          rules: [
            {
              operator: "=",
              value: 1,
              price: 5,
            },
            {
              operator: "=",
              value: 2,
              price: 9,
            },
          ],
        },
      ],
    });
  }
}
