import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="forms--variant-price"
export default class extends Controller {
  static targets = ["price", "priceWithVat"];
  static values = {
    vatCoefficient: Number,
  };

  priceUpdated() {
    this.priceWithVatTarget.value = (
      parseFloat(this.priceTarget.value) * this.vatCoefficientValue
    ).toFixed(2);
  }

  priceWithVatUpdated() {
    this.priceTarget.value = (
      parseFloat(this.priceWithVatTarget.value) / this.vatCoefficientValue
    ).toFixed(2);
  }
}
